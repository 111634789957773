.fade-enter {
  opacity: 0;
  //transition: opacity 700ms ease-in;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  z-index: 1;
  transition: opacity 700ms ease-in;
}

.fade-exit {
  display: none;
  opacity: 0;
  z-index: -1;
}
