.mobile-nav {
  z-index: 100;
  position: fixed;
  top: var(--global--header-height-mobile);
  width: 100vw;
  right: -100vw;
  height: 100%;
  transition: right 250ms ease;
  box-sizing: border-box;

  @media only screen and ($above-m) {
    display: none;
  }

  &--slide-out {
    right: 0;
    transition: right 250ms ease;
  }

  &__list {
    display: block;
    list-style-type: none;
    width: 100%;
    height: calc(100vh - var(--global--header-height-mobile));
    margin: 0;
    padding: var(--global--header-height-mobile) 0 0 0;
    background: var(--global--color-background);
    &__item {
      display: block;
      cursor: pointer;
      width: 100%;
      flex-basis: 100%;
      color: var(--global--color-emphasize);
      font-family: Futura;
      font-size: var(--global--font-size-l);
      text-align: center;
      text-decoration: none;
      padding: 15px 0 15px 0;
      &__close-icon {
        position: absolute;
        top: 30px;
        right: var(--global--default-padding);
        width: 32px;
        height: 32px;
        background-color: var(--global--color-background);
        cursor: auto;
      }
    }
  }
}
