:root {
  /**
   * Global font jobs.
   * Fonts should be set them to the job the text is doing. Avoid assigning
   * specific font faces on elements to keep future updates simple.
   *
   * Body. Versatile and facilitates reading even long-form content comfortably.
   *
   * Display. To attract attention and set the tone. Good for top-level headings
   * and hero text.
   *
   * Lettering. Artistic font for displaying an individual letter with flair.
   *
   * Signage. To help people find their way around. Use for labels in a grid of
   * products, buttons, strong calls to action, and footers full of links.
   *
   * Small. For clarity at small sizes. Captions, labels, footnotes, or where
   * there is limited screen real estate.
   *
   * Tabular. Quickly scannable to gather data and make comparisons. Ideal for
   * tables of contents, charts, or anywhere data needs to be easily understood.
   *
   * Technical. For technical accuracy where glyph placement is crucial. Use
   * when displaying math formulas, code, or even poetry and infographics.
   */
  --global--font-body: "Helvetica Neue",Arial,sans-serif;
  --global--font-display: "Helvetica Neue",Arial,sans-serif;
  //--global--font-logo: Futura, sans-serif;

  /**
 * Global font sizes.
 * Specified in t-shirt sizing.
 */
  --global--font-size-xxs: 12px; /* 12px */
  --global--font-size-xs: 0.875rem; /* ~14px */
  --global--font-size-s: 1rem; /* ~16px */
  --global--font-size-m: 1.188rem; /* ~19px */
  --global--font-size-l: 1.5rem; /* ~24px */
  --global--font-size-xl: 1.875rem; /* ~30px */
  --global--font-size-xxl: 2.25rem; /* ~36px */
  --global--font-size-3xl: 2.5rem; /* 40px */
  --global--font-size-4xl: 3rem; /* 48px */
  --global--font-size-5xl: 4.063rem; /* ~65px */
  --global--font-size-6xl: 4.5rem; /* ~72px */

  /* Line Height */
  --global--line-height-body: 1.6;

  /**
 * Global font weights.
 *
 * @since 1.5
 */
  --global--font-weight-thin: 100;
  --global--font-weight-extra-light: 200;
  --global--font-weight-light: 300;
  --global--font-weight-regular: 400;
  --global--font-weight-medium: 500;
  --global--font-weight-semi-bold: 600;
  --global--font-weight-bold: 700;
  --global--font-weight-extra-bold: 800;
  --global--font-weight-black: 900;
  --global--font-weight-ultra-black: 950;
}
