:root {
  --global--color-text: #444;
  --global--color-background: #fff;
  --global--color-subtle-offset: #f3f3f3;
  --global--color-accent: #ea9500;
  --global--color-overlay: rgba(0,0,0,0.5);
  --global--color-overlay-light: rgba(255,255,255,0.5);
  --global--color-overlay-text: #fff;
  --global--color-emphasize: #000;
  --global--color-link: #2d82cb;
  --global--color-button: #ea9500;
  --global--color-button-text: #fff;
}
