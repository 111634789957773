body {
  overflow-x: hidden;
}

p {
  margin: var(--global--default-padding);
  color: var(--global--color-text);
  line-height: 1.6rem;

  @media screen and ($above-s) {
    margin: var(--global--default-padding) 0;
  }
}

a {
  color: var(--global--color-link);
}

.cover-image {
  margin: var(--global--default-padding);
  width: calc(100% - (var(--global--default-padding) * 2));
}
