.about {
  max-width: var(--global--max-content-width);
  margin: 0 auto;

  @media screen and ($above-s) {
    display: flex;
    justify-content: space-around;
  }
}

.about__image {
  display: block;
  width: calc(60% - (var(--global--default-padding) * 2));
  margin: 0 auto var(--global--default-padding);
  padding: var(--global--default-padding);

  @media screen and ($above-s) {
    flex-basis: 50%;
  }

  img {
    width: 100%;
  }
}

.about__content {
  flex-basis: 40%;
}
