.nav {
  position: absolute;
  top: calc(50% - 12px);
  right: var(--global--default-padding);
  width: 32px;
  height: 24px;

  @media only screen and ($above-m) {
    position: relative;
    top: auto;
    right: auto;
    display: block;
    clear: both;
    width: 100%;
    flex-basis: 100%;
    height: auto;
  }
}

