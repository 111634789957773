@font-face {
  font-family: 'Futura';
  src: url('../../fonts/Futura-Bold.woff2') format('woff2'),
  url('../../fonts/Futura-Bold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Medium';
  src: url('../../fonts/futura-medium-bt.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Futura Condensed';
  src: url('../../fonts/FuturaBT-BoldCondensed.woff2') format('truetype'),
  url('../../fonts/FuturaBT-BoldCondensed.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

