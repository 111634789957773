body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.outside-click-wrapper {
  max-width: var(--global--max-content-width);
  margin: 0 auto;
}
