.header {
  display: flex;
  align-items: center;
  position: relative;
  padding: var(--global--default-padding);
  height: var(--global--header-height-mobile);
  box-sizing: border-box;

  @media only screen and ($above-m) {
    height: auto;
    justify-content: center;
    flex-wrap: wrap;
  }

  > a {
    text-decoration: none;
    display: flex;
  }
}

.header__logo {
  float: left;
  margin-right: calc(var(--global--default-padding) / 2);
  height: var(--global--logo-height-small);
  width: var(--global--logo-height-small);
  border-radius: 50%;

  @media only screen and ($above-m) {
    height: var(--global--logo-height-large);
    width: var(--global--logo-height-large);
  }
}

.header__title,
.header__title {
  display: none;
  margin: 0;
  font-family: Futura, sans-serif;
  font-size: var(--global--font-size-s);
  color: var(--global--color-text);
  text-decoration: none;

  @media screen and ($above-m) {
    display: block;
    line-height: var(--global--logo-height-large);
    font-size: var(--global--font-size-3xl);
  }
}
