.hamburger {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  height: 23px;
  margin: 0 auto;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  width: 32px;

  @media only screen and ($above-m) {
    display: none;
  }

  &--active ~ .mobile-nav {
      right: 0;
      transition: right 250ms ease;
  }
}

.hamburger span {
  background-color: var(--global--color-text);
  border-radius: 3px;
  display: block;
  height: 3px;
  left: 0;
  opacity: 1;
  position: absolute;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
  width: 100%;
}

.hamburger span:nth-child(1) {
  top: 0px;
}

.hamburger span:nth-child(2),.hamburger  span:nth-child(3) {
  top: 10px;
}

.hamburger span:nth-child(4) {
  top: 20px;
}

.hamburger.hamburger--active span:nth-child(1) {
  top: 6px;
  width: 0%;
  left: 50%;
}

.hamburger.hamburger--active span:nth-child(2) {
  transform: rotate(45deg);
}

.hamburger.hamburger--active span:nth-child(3) {
  transform: rotate(-45deg);
}

.hamburger.hamburger--active span:nth-child(4) {
  top: 9px;
  width: 0%;
  left: 50%;
}
