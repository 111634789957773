.desktop-nav {
  margin-top: var(--global--default-vertical-spacing);
}

.desktop-nav__list {
  display: flex;
  list-style: none;
  align-items: center;
  margin: 0;

  @media only screen and ($above-m) {
    justify-content: center;
  }
}

.desktop-nav__list__item {
  display: none;

  @media only screen and ($above-m) {
    display: block;
    margin-right: 30px;
    padding: 5px;
    color: var(--global--color-emphasize);
    font-family: "Futura Medium";
    text-transform: uppercase;
    font-size: var(--global--font-size-xxs);
    text-decoration: none;
  }

  &.active {
    text-decoration: underline;
  }

  &:last-child {
    margin-right: 0;
  }
}
