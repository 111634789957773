
:root {
  --global--default-padding: 20px;
  --global--default-margin: 50px;
  --global--default-vertical-spacing: 20px;
  --global--header-height-mobile: 118px;
  --global--header-height-large: 150px;
  --global--logo-height-small: 78px;
  --global--logo-height-large: 70px;
  --global--max-content-width: 1200px;
  --global--max-inner-width: 800px;
  --global--column-width: 700px;
}
