.drawer {
  overflow: hidden;
  max-width: var(--global--max-content-width);
  margin: 0 auto var(--global--default-margin);
  padding-bottom: var(--global--default-margin);
  background-color: var(--global--color-subtle-offset);

  &.enter {
    max-height: 0;
  }

  &.enter-done {
    overflow: visible;
    max-height: 5000px;
    transition: max-height .6s;
  }

  &.exit {
    max-height: 0;
    transition: max-height .6s ease-out;
  }
}

.drawer-content {
  position: relative;
  color: var(--global--color-text);
  display: block;
  overflow: initial;
  padding: var(--global--default-padding);
  text-align: center;
  margin: 0 auto;
}

.drawer-title {
  color: var(--global--color-text);
  font-family: var(--global--font-display);
  font-size: var(--global--font-size-xxl);
  margin: 40px auto;
  max-width: calc(var(--global--max-inner-width) * 0.8);
}

.drawer-close {
  background-color: transparent;
  border: none;
  color: var(--global--color-text);
  cursor: pointer;
  display: block;
  float: right;
  font-size: 30px;
  height: 40px;
  position: sticky;
  top: 0;
  right: 0;
  width: 40px;
  z-index: 3;
}

.drawer-close * {
  cursor: pointer;
}

.drawer-image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.drawer-image {
  margin: var(--global--default-padding);

  img {
    width: 100%;
    max-width: calc(100% - 40px);
  }

  &__wide {
    flex-basis: 100%;
  }

  &__square {
    flex-basis: 44%;

    img {
      max-width: 100%;
    }
  }

  &__gif {
    flex-basis: 50%;

    img {
      max-width: 100%;
    }
  }

  &__phone {
    max-width: 25%;
    margin-top: var(--global--default-margin);

    img {
      max-width: 100%;
    }
  }

  &__phone--tilted {
    max-width: 25%;
    transform: rotate(5deg);
    margin-top: var(--global--default-margin);

    img {
      max-width: 100%;
    }
  }
}

a.drawer-image {
  cursor: pointer;

  > * {
    cursor: pointer;
    display: block;
  }
}

.drawer-text {
  line-height: var(--global--line-height-body);
  max-width: var(--global--column-width);
  margin: var(--global--default-margin) auto;
  font-size: var(--global--font-size-m);
  text-align: left;
}

.drawer-link {
  position: sticky;
  background-color: var(--global--color-button);
  bottom: var(--global--default-padding);
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  color: var(--global--color-button-text);
  font-family: var(--global--font-display);
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 3px;
  font-size: var(--global--font-size-s);
}
