.grid {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: calc(100vw - var(--global--default-padding));
  margin: calc(var(--global--default-padding) / 2);
  box-sizing: border-box;

  @media screen and ($above-l) {
    max-width: var(--global--max-content-width);
    margin: calc(var(--global--default-padding) / 2) auto;
  }
}
.grid__item {
  display: block;
  position: relative;
  width: calc(50% - var(--global--default-padding));
  flex-basis: calc(50% - var(--global--default-padding));
  box-sizing: border-box;
  margin: calc(var(--global--default-padding) / 2);
  cursor: pointer;

  @media screen and ($above-s) {
    width: calc(33.3% - var(--global--default-padding));
    flex-basis: calc(33.3% - var(--global--default-padding));
  }

  @media screen and (min-width: 1000px) {
    width: calc(25% - var(--global--default-padding));
    flex-basis: calc(25% - var(--global--default-padding));
  }
}

.grid__categories {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.grid__category-illustration,
.grid__category-development {
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-left: 5px;

  @media screen and ($above-s) {
    display: none;

    .grid__item:hover & {
      display: inline-block;
    }
  }


}

.grid__category-illustration {
  background: url("../../images/illustration-icon.png") no-repeat center;
  background-size: contain;
  right: 10px;
}

.grid__category-development {
  background: url("../../images/code-icon.png") no-repeat center;
  background-size: contain;
}

.grid__item-image {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  img {
    width: 100%;
    min-height: 100%;
  }
}
.grid__item-title {
  position: absolute;
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: calc(var(--global--default-padding) / 2);
  font-family: var(--global--font-display);
  color: var(--global--color-overlay-text);
  background-color: var(--global--color-overlay);
  box-sizing: border-box;
  transition: opacity .3s;

  .grid__item:hover &,
  .grid__item:active & {
    opacity: 1;
  }
}
